import Banner from '../components/home-page/Banner';
import HeroSlider from '../components/home-page/HeroSlider';
import OffersList from '../components/home-page/OffersList';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import UseClickOutside from '../hooks/UseClickOutside';
import Modal from '../components/Modal';
import { setOffersRedux } from '../redux/features/offersSlice';

const Home = ({ location, trackClick }) => {
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [activeTab, setActiveTab] = useState('all offers');
  const [offersCount, setOffersCount] = useState();
  const [page, setPage] = useState(1);
  const auth = useSelector((state) => state?.auth?.user);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clickedOfferData, setClickedOfferData] = useState();

  const modalRef = useRef(null);
  const offersEndRef = useRef(null); // Ref for the end of the offers list

  UseClickOutside(modalRef, () => setShowModal(false));

  const handleOpenModal = (offerData) => {
    trackClick('Button', 'Redeem Offer Button Carousel');
    setClickedOfferData(offerData);

    setTimeout(() => {
      setShowModal(true);
    }, 100);
  };

  const fetchOffers = async () => {
    setLoading(true);
    try {
      let response;

      if (location?.lat && location?.long) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/offers`,
          {
            params: {
              lat: location.lat,
              lon: location.long,
              page,
              per_page: 20,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          },
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/offers`,
          {
            params: {
              page,
              per_page: 20,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          },
        );
      }

      setOffersCount(response?.data?.total);
      setOffers(response?.data?.data);
      dispatch(setOffersRedux(response?.data?.data));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  // const handleScroll = () => {
  //   if (
  //     offersEndRef.current &&
  //     !loading &&
  //     offersEndRef.current.getBoundingClientRect().top <= window.innerHeight &&
  //     offers.length < offersCount
  //   ) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  useEffect(() => {
    fetchOffers();
  }, []);

  useEffect(() => {
    if (fetchAgain) {
      fetchOffers();
      setFetchAgain(false);
    }
  }, [fetchAgain]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [loading]);

  return (
    <>
      {showModal && (
        <Modal
          forwardRef={modalRef}
          closeModal={() => setShowModal(false)}
          offerData={clickedOfferData}
          trackClick={trackClick}
        />
      )}
      <HeroSlider handleOpenModal={handleOpenModal} trackClick={trackClick} />
      <OffersList
        trackClick={trackClick}
        handleOpenModal={handleOpenModal}
        loading={loading}
        setLoading={setLoading}
        setFetchAgain={setFetchAgain}
        offers={offers}
        offersCount={offersCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {!auth?.token && <Banner trackClick={trackClick} />}
      {/* This div is a marker for the end of the offers list */}
      <div ref={offersEndRef} />
    </>
  );
};

export default Home;
