import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const HeroSliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;

const SingleSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 0 2rem 0;
  min-height: 699px;
`;

const SingleSlideHeading = styled.h1`
  font-size: 4rem;
  font-weight: 800;
  color: white;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1024px) {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  ${({ direction }) => (direction === 'left' ? 'left: 20px;' : 'right: 20px;')};

  @media (max-width: 1024px) {
    ${({ direction }) => (direction === 'left' ? 'left: 5px;' : 'right: 5px;')};
    padding: 2px;
  }

  svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
`;

const Indicators = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Indicator = styled.div`
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ $active }) =>
    $active ? 'white' : 'rgba(255, 255, 255, 0)'};
  border: 2px solid white;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const CouponDiv = styled.div`
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0.55);
  margin-top: 0;
  width: 1000px;
  height: auto;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 95%;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const CouponImage = styled.div`
  width: 50%;
  height: 300px;
  background-image: url(${({ $img }) => $img});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    background-size: contain;
    height: 240px;
  }
`;

const CouponTextContaier = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 50%;
  color: white;
  box-sizing: border-box;
  padding: 0 2rem;

  @media (max-width: 1024px) {
    width: 100%;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    vertical-align: middle;
    width: 88%;
    text-align: left;

    @media (max-width: 1024px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
`;

const SingleOfferButton = styled.button`
  margin-top: 1rem;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: #8ac926;
  color: white;
  width: 90%;
  padding: 10px;
  font-weight: 800;
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);

  @media (max-width: 768px) {
    width: 200px;
    font-size: 0.9rem;
  }
`;

const SingleSlideLogo = styled.div`
  width: 200px;
  height: 60px;
  border-radius: 0.5rem;
  background-size: 125px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;

  @media (max-width: 768px) {
    width: 180px;
    height: 50px;
    background-size: 80px;
    margin-top: 1rem;
  }
`;

const HeroSlider = ({ handleOpenModal, trackClick }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [offersForSlider, setOffersForSlider] = useState();

  const handlePrev = () => {
    trackClick('Arrow', 'Left Carousel Arrow');
    setCurrentSlide(
      currentSlide === 0 ? offersForSlider.length - 1 : currentSlide - 1,
    );
  };

  const handleNext = () => {
    trackClick('Arrow', 'Right Carousel Arrow');
    setCurrentSlide(
      currentSlide === offersForSlider.length - 1 ? 0 : currentSlide + 1,
    );
  };

  const fetchOffers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/slider-offers`,
        {},
      );

      setOffersForSlider(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <HeroSliderContainer>
      {offersForSlider?.length > 0 && (
        <SingleSlide
          style={{
            backgroundImage: `url(/slider-bg.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Arrow direction="left" onClick={handlePrev}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
          </Arrow>
          <SingleSlideHeading>Great Offers For You!</SingleSlideHeading>

          <CouponDiv>
            <CouponImage $img={offersForSlider[currentSlide]?.image_url} />

            <CouponTextContaier>
              <SingleSlideLogo
                style={{
                  backgroundImage: `url(${offersForSlider[currentSlide]?.client?.logo_url})`,
                }}
              />

              <h2>{offersForSlider[currentSlide]?.title}</h2>
              <SingleOfferButton
                onClick={() => handleOpenModal(offersForSlider[currentSlide])}
              >
                Redeem This Offer
              </SingleOfferButton>
            </CouponTextContaier>
          </CouponDiv>

          <Indicators>
            {offersForSlider.map((_, i) => (
              <Indicator
                key={i}
                $active={i === currentSlide}
                onClick={() => setCurrentSlide(i)} // Set current slide on click
              />
            ))}
          </Indicators>

          <Arrow direction="right" onClick={handleNext}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </Arrow>
        </SingleSlide>
      )}
    </HeroSliderContainer>
  );
};

export default HeroSlider;
