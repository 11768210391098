import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    message: "",
    type: "" 
};

//success or warning or info or error for the TYPE

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.isOpen = action.payload.isOpen;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
    },
});

export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;