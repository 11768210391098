import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isVisible: true,
    isAgreed: null,
};

export const dislcaimerSlice = createSlice({
    name: "disclaimer",
    initialState,
    reducers: {
        setDisclaimer: (state, action) => {
            state.isVisible = action.payload.isVisible;
            state.isAgreed = action.payload.isAgreed;
        },
    },
});

export const { setDisclaimer } = dislcaimerSlice.actions;

export default dislcaimerSlice.reducer;