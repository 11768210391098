import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { setSnackbar } from '../redux/features/snackbarSlice';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  min-height: 600px;

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 95%;
    min-height: 400px;
  }

  h1 {
    font-weight: 600;
    font-size: 2.8rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

const SignupContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;

  input {
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgray;
  }

  p {
    width: 50%;
    font-size: 1.1rem;
    line-height: 1.6rem;
    text-align: center;

    @media (max-width: 1024px) {
      width: 95%;
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
  }
`;

const SignupButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  color: white;
  padding: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  text-shadow: gray 1px 0 1px;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
`;

const ErrorText = styled.p`
  font-size: 0.85rem;
  color: red;
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
`;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth?.user);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      navigate('/');
    }
  }, [auth]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setSuccess(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/forgot-password`,
        {
          email: formData.email,
        },
      );
      setLoading(false);
      setSuccess(true);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Email Sent!',
          type: 'success',
        }),
      );

      setFormData({
        email: '',
      });

      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: error?.response?.data?.message,
          type: 'error',
        }),
      );
    }
  };
  return (
    <>
      <LoginContainer>
        <h1>
          {success
            ? 'Check Your email for a password reset link'
            : 'Type your email to reset password'}
        </h1>

        {success ? (
          <SignupContainerInputs>
            <p>
              Your request to reset your password has been received. Please note
              that it might take a few moments for the reset email to arrive in
              your inbox. If you don’t see it, be sure to check your spam or
              junk folder.
            </p>
          </SignupContainerInputs>
        ) : (
          <form onSubmit={handleSubmit}>
            <SignupContainerInputs>
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address*"
                required
              />

              {error && <ErrorText>{error}</ErrorText>}

              <SignupButton>{loading ? 'Sending..' : 'Submit'}</SignupButton>
            </SignupContainerInputs>
          </form>
        )}
      </LoginContainer>
    </>
  );
};

export default ForgotPassword;
