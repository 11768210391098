import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 600px;
  }
`;

const BannerContainer = styled.div`
  height: 350px;
  width: 100%;
  z-index: -1;
  background-color: #48cae4;
  position: absolute;

  @media (max-width: 1359px) {
    height: 300px;
  }
`;

const MyAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  margin: 2rem auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
  min-height: 800px;
  z-index: 2;
  background-color: white;

  @media (max-width: 768px) {
    min-height: 500px;
    width: 95%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  svg {
    width: 42px;

    @media (max-width: 768px) {
      width: 38px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const HeaderText = styled.p`
  font-size: 1.6rem;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const HeaderSmalleText = styled.p`
  color: grey;
  font-size: 1rem;
  margin-top: 0.25rem;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 1.05rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

const UserLink = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const MyAccount = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (!auth?.token) {
      navigate('/');
    }
  }, [auth, navigate]);

  console.log(auth?.user);
  return (
    <MainContainer>
      <BannerContainer />{' '}
      <MyAccountContainer>
        <Header>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
          </svg>

          <div>
            <HeaderText>
              {auth?.user?.first_name} {auth?.user?.last_name}
            </HeaderText>
            <HeaderSmalleText>{auth?.user?.email}</HeaderSmalleText>
          </div>
        </Header>

        <UserInfo>
          <p>Email: {auth?.user?.email || 'Not available'}</p>
          <p>Phone: {auth?.user?.phone || 'Not available'}</p>
          <p>Postal Code: {auth?.user?.zip || 'Not available'}</p>
        </UserInfo>

        <UserLink onClick={() => navigate('/my-favorites')}>
          My Favorite Offers
        </UserLink>
      </MyAccountContainer>
    </MainContainer>
  );
};

export default MyAccount;
