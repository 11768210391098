import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from '../components/Modal';

const MyFavoritesContainer = styled.div`
  min-height: 800px;
  width: 80%;
  margin: 2rem auto;

  @media (max-width: 768px) {
    min-height: 600px;
    width: 95%;
  }
`;

const MyFavoritesHeading = styled.h1``;

const OffersListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 320px); /* 4 columns by default */
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 1420px) {
    grid-template-columns: repeat(3, 320px); /* 3 columns on medium screens */
  }

  @media (max-width: 1089px) {
    grid-template-columns: repeat(2, 320px); /* 2 columns on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 320px); /* 1 column on mobile */
  }
`;

const SingleOffer = styled.div`
  width: 320px;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  position: relative;
`;

const SingleOfferLogo = styled.div`
  width: 180px;
  height: 120px;
  margin: 1rem auto auto auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const SingleOfferDiscout = styled.div`
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.2rem;
`;

const NoOffersText = styled.p`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 8rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const SingleOfferDescription = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  width: 90%;
  margin: 0 auto;
  height: 60px;
`;

const SingleOfferSmallDescription = styled.p`
  text-align: left;
  font-size: 0.8rem;
  line-height: 18px;
  font-weight: 500;
  width: 90%;
  margin: 0 auto;
  color: grey;
  height: 110px;
`;

const BreakLine = styled.div`
  border: 1px solid lightgray;
  width: 90%;
  margin: 0 auto;
  height: 1px;
`;

const SingleOfferRedemptions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 97%;
  justify-content: left;
  padding: 0.75rem 0.5rem;

  svg {
    width: 26px;
    height: 26px;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const SingleOfferButton = styled.button`
  margin: 0 auto;
  display: block;
  border: 3px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
  outline: none;
  border-radius: 10px;
  color: white;
  width: 90%;
  padding: 14px;
  font-weight: 800;
  font-size: 1.05rem;
  text-transform: uppercase;
  cursor: pointer;
`;

const MyFavorites = () => {
  const navigate = useNavigate();
  const offers = useSelector((state) => state.offers?.offers);
  const auth = useSelector((state) => state?.auth?.user);
  const modalRef = useRef(null);
  const [clickedOfferData, setClickedOfferData] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = (offerData) => {
    setClickedOfferData(offerData);

    setTimeout(() => {
      setShowModal(true);
    }, 100);
  };

  useEffect(() => {
    if (!auth?.token) {
      navigate('/');
    }
  }, [auth, navigate]);

  // Filter offers to include only favorites
  const favoriteOffers =
    offers?.filter((offer) => offer.favorite === true) || [];
  return (
    <>
      <MyFavoritesContainer>
        <MyFavoritesHeading>
          {favoriteOffers?.length === 0
            ? 'No Favorite Offers'
            : 'My Favorite Offers'}
        </MyFavoritesHeading>
        <OffersListContainer>
          {favoriteOffers.map((data, index) => (
            <SingleOffer onClick={() => handleOpenModal(data)} key={index}>
              <SingleOfferLogo
                style={{
                  backgroundImage: `url(${data?.client?.logo_url})`,
                }}
              />
              <SingleOfferDescription>
                {data?.short_description}
              </SingleOfferDescription>
              <SingleOfferDiscout style={{ backgroundColor: '#009FC2' }}>
                <p
                  style={{
                    color: 'white',
                  }}
                >
                  {data?.discount}
                </p>
              </SingleOfferDiscout>

              <SingleOfferSmallDescription>
                {data?.description}
              </SingleOfferSmallDescription>

              <BreakLine />

              <SingleOfferRedemptions>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16l-97.5 0c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8l97.5 0c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32L0 448c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32l-64 0z" />
                </svg>
                <p>{data?.redemptions} Redemptions</p>
              </SingleOfferRedemptions>

              <SingleOfferButton>Redeem This Offer</SingleOfferButton>
            </SingleOffer>
          ))}
        </OffersListContainer>
      </MyFavoritesContainer>

      {showModal && (
        <Modal
          forwardRef={modalRef}
          closeModal={() => setShowModal(false)}
          offerData={clickedOfferData}
        />
      )}
    </>
  );
};

export default MyFavorites;
