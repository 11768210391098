import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { setSnackbar } from '../../redux/features/snackbarSlice';

const OffersContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const OffersNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  h2 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #3d3d3d;

    @media (max-width: 1024px) {
      font-size: 1.6rem;
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const OffersNavOptions = styled.div`
  display: flex;
  gap: 1rem;

  div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
  }

  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  svg {
    width: 20px;
  }
`;

const OffersListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 320px); /* 4 columns by default */
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
  position: relative;
  min-height: 200px;

  @media (max-width: 1420px) {
    grid-template-columns: repeat(3, 320px); /* 3 columns on medium screens */
  }

  @media (max-width: 1089px) {
    grid-template-columns: repeat(2, 320px); /* 2 columns on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 320px); /* 1 column on mobile */
  }
`;

const SingleOffer = styled.div`
  width: 320px;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  position: relative;
`;

const SingleOfferLogo = styled.div`
  width: 180px;
  height: 120px;
  margin: 1rem auto auto auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const SingleOfferDiscout = styled.div`
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.2rem;
`;

const NoOffersText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const SingleOfferDescription = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  width: 90%;
  margin: 0 auto;
  height: 60px;
`;

const SingleOfferSmallDescription = styled.p`
  text-align: left;
  font-size: 0.8rem;
  line-height: 18px;
  font-weight: 500;
  width: 90%;
  margin: 0 auto;
  color: grey;
  height: 110px;
`;

const BreakLine = styled.div`
  border: 1px solid lightgray;
  width: 90%;
  margin: 0 auto;
  height: 1px;
`;

const SingleOfferRedemptions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 97%;
  justify-content: left;
  padding: 0.75rem 0.5rem;

  svg {
    width: 26px;
    height: 26px;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const SingleOfferButton = styled.button`
  margin: 0 auto;
  display: block;
  border: 3px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
  outline: none;
  border-radius: 10px;
  color: white;
  width: 90%;
  padding: 14px;
  font-weight: 800;
  font-size: 1.05rem;
  text-transform: uppercase;
  cursor: pointer;
`;

const FavoritesHeart = styled.svg`
  width: 24px;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  fill: red;
  cursor: pointer;
`;

const OffersList = ({
  trackClick,
  offers,
  offersCount,
  setFetchAgain,
  setLoading,
  loading,
  handleOpenModal,
  activeTab,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.user);

  const handleFavoriteAction = async (offerId, method) => {
    trackClick('Icon', 'Add To Favorites');
    setLoading(true);
    try {
      const response = await axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/api/offers/${offerId}/favorite`,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      dispatch(
        setSnackbar({
          isOpen: true,
          message: response?.data?.message,
          type: 'success',
        }),
      );

      setFetchAgain(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToFavorites = (offerId) =>
    handleFavoriteAction(offerId, 'post');
  const handleRemoveFromFavorites = (offerId) =>
    handleFavoriteAction(offerId, 'delete');

  return (
    <OffersContainer>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '600px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <OffersNav>
            <h2>{offersCount} Exclusive Offers For You!</h2>
            {auth?.token && (
              <OffersNavOptions>
                <div onClick={() => setActiveTab('all offers')}>
                  <svg
                    style={{
                      fill: activeTab === 'all offers' ? '#00ccff' : 'black',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5L0 80C0 53.5 21.5 32 48 32l149.5 0c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                  </svg>
                  <p
                    style={{
                      color: activeTab === 'all offers' ? '#00ccff' : 'black',
                    }}
                  >
                    All Offers
                  </p>
                </div>

                <div onClick={() => setActiveTab('favorites')}>
                  <svg
                    style={{
                      fill: activeTab === 'favorites' ? '#00ccff' : 'black',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                  </svg>
                  <p
                    style={{
                      color: activeTab === 'favorites' ? '#00ccff' : 'black',
                    }}
                  >
                    Favorites
                  </p>
                </div>
              </OffersNavOptions>
            )}
          </OffersNav>

          <OffersListContainer>
            {offers?.filter((data) =>
              activeTab === 'favorites' ? data?.favorite : true,
            ).length === 0 && activeTab === 'favorites' ? (
              <NoOffersText>There are no favorite offers</NoOffersText>
            ) : (
              offers
                ?.filter((data) =>
                  activeTab === 'favorites' ? data?.favorite : true,
                )
                .map((data, index) => (
                  <SingleOffer
                    onClick={() => {
                      trackClick('Button', 'Redeem Offers Button');
                      handleOpenModal(data);
                    }}
                    key={index}
                  >
                    {auth?.token && (
                      <FavoritesHeart
                        style={
                          data?.favorite ? { fill: 'red' } : { fill: 'grey' }
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (data?.favorite) {
                            handleRemoveFromFavorites(data?.id);
                          } else {
                            handleAddToFavorites(data?.id);
                          }
                        }}
                      >
                        <path
                          d={
                            data?.favorite
                              ? 'M462.3 62.7C407.5 15.9 324.3 24 265.9 79.3l-9.9 10.4-9.9-10.4C187.7 24 104.5 15.9 49.7 62.7c-54.6 46.5-58 118.7-10.9 167.4l175.1 179.6c12.6 12.9 32.9 12.9 45.5 0l175.1-179.6c47-48.7 43.7-120.9-10.8-167.4z'
                              : 'M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z'
                          }
                        />
                      </FavoritesHeart>
                    )}

                    <SingleOfferLogo
                      style={{
                        backgroundImage: `url(${data?.client?.logo_url})`,
                      }}
                    />
                    <SingleOfferDescription>
                      {data?.short_description}
                    </SingleOfferDescription>
                    <SingleOfferDiscout
                      // style={{
                      //   backgroundColor: data?.discount_color
                      //     ? data?.discount_color
                      //     : 'green',
                      // }}
                      style={{ backgroundColor: '#009FC2' }}
                    >
                      <p
                        style={{
                          color: 'white',
                        }}
                      >
                        {data?.discount}
                      </p>
                    </SingleOfferDiscout>

                    <SingleOfferSmallDescription>
                      {data?.description}
                    </SingleOfferSmallDescription>

                    <BreakLine />

                    {/* <SingleOfferAdd src={data?.image_url} /> */}
                    <SingleOfferRedemptions>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16l-97.5 0c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8l97.5 0c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32L0 448c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32l-64 0z" />
                      </svg>
                      <p>{data?.redemptions} Redemptions</p>
                    </SingleOfferRedemptions>

                    <SingleOfferButton>Redeem This Offer</SingleOfferButton>
                  </SingleOffer>
                ))
            )}
          </OffersListContainer>
        </>
      )}
    </OffersContainer>
  );
};

export default OffersList;
