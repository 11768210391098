import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Banner from '../components/home-page/Banner';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { registerUser } from '../redux/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../redux/features/snackbarSlice';
import { InputMask } from '@react-input/mask';

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: 35%;
  min-height: 50dvh;

  @media (max-width: 1459px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  h1 {
    font-weight: 600;
    font-size: 2.8rem;
  }
`;

const SignupContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;

  input {
    flex-basis: calc(50% - 1rem);
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgray;

    @media (max-width: 1024px) {
      flex-basis: 75%;
    }

    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }

  input[name='email'] {
    flex-basis: 100%;
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;

  input {
    margin-right: 0.5rem;
  }

  label {
    font-size: 1rem;

    a {
      color: #008c9a;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;

const SignupButton = styled.button`
  width: 80%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
  color: white;
  padding: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  text-shadow: gray 1px 0 1px;
`;

const DisclaimerText = styled.p`
  flex-basis: 100%;
  margin-top: 1rem;
  font-size: 0.875rem;
  text-align: left;
  color: #666;
`;

const LoginText = styled.p`
  flex-basis: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
  color: black;

  span {
    color: #008c9a;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`;

const ErrorText = styled.p`
  font-size: 0.85rem;
  color: red;
  text-align: center;
  font-weight: 600;
`;

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    zipCode: '',
    password: '',
    confirmPassword: '',
    newsletter: false,
    terms: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const auth = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (auth?.token) {
      navigate('/');
    }
  }, [auth]);

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value.trimStart(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const trimmedFormData = {
      ...formData,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      phoneNumber: formData.phoneNumber.trim(),
      zipCode: formData.zipCode.trim(),
      password: formData.password.trim(),
      confirmPassword: formData.confirmPassword.trim(),
    };

    if (
      !trimmedFormData.firstName ||
      !trimmedFormData.lastName ||
      !trimmedFormData.email ||
      !trimmedFormData.phoneNumber ||
      !trimmedFormData.zipCode ||
      !trimmedFormData.password ||
      !trimmedFormData.confirmPassword
    ) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    // Extract digits from the phone number and validate length
    const phoneDigits = trimmedFormData.phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

    if (phoneDigits.length < 10 || phoneDigits.length > 11) {
      setError('Please enter a valid US phone number');
      setLoading(false);
      return;
    }

    // Validate ZIP code format
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    if (!zipCodeRegex.test(trimmedFormData.zipCode)) {
      setError('Please enter a valid US ZIP code.');
      setLoading(false);
      return;
    }

    if (trimmedFormData.password !== trimmedFormData.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/register`,
        {
          first_name: trimmedFormData.firstName,
          last_name: trimmedFormData.lastName,
          email: trimmedFormData.email,
          phone: trimmedFormData.phoneNumber,
          zip: trimmedFormData.zipCode,
          password: trimmedFormData.password,
          password_confirmation: trimmedFormData.confirmPassword,
          terms: formData.terms,
          sms_checkbox_mrg: formData.newsletter,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Your sign up was successful',
          type: 'success',
        }),
      );

      const userWithToken = {
        user: response?.data?.user,
        token: response?.data?.token,
      };

      dispatch(registerUser(userWithToken));

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        password: '',
        confirmPassword: '',
        newsletter: false,
        terms: false,
      });

      setTimeout(() => {
        navigate('/');
      }, 500);
    } catch (error) {
      setLoading(false);

      setError(error?.response?.data?.error);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'There was a problem with your sign up.',
          type: 'error',
        }),
      );
    }
  };

  return (
    <>
      <SignupContainer>
        <h1>Sign Up!</h1>
        <form onSubmit={handleSubmit}>
          <SignupContainerInputs>
            <input
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name*"
              required
              pattern=".*\S.*"
            />
            <input
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name*"
              required
              pattern=".*\S.*"
            />
            <input
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address*"
              required
              pattern=".*\S.*"
            />
            <input
              name="zipCode"
              type="number"
              value={formData.zipCode}
              onChange={handleChange}
              placeholder="Zip Code*"
              required
              pattern=".*\S.*"
            />
            <InputMask
              name="phoneNumber"
              required
              placeholder="Phone Number*"
              onChange={handleChange}
              mask="+1 (___) ___-__-__"
              replacement={{ _: /\d/ }}
            />
            <input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password*"
              required
              pattern=".*\S.*"
            />
            <input
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password*"
              required
              pattern=".*\S.*"
            />

            <CheckboxContainer>
              <input
                name="terms"
                type="checkbox"
                checked={formData.terms}
                onChange={handleChange}
                required
              />
              <label htmlFor="newsletter">
                I agree to the welcomehomeowner.com{' '}
                <a
                  href="https://www.mrginc.com/terms-conditions"
                  target="_blank"
                  rel="noreferer"
                >
                  terms of service
                </a>
              </label>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                name="newsletter"
                type="checkbox"
                checked={formData.newsletter}
                onChange={handleChange}
              />
              <label htmlFor="newsletter">
                I agree to be contacted by SMS/Text at the phone number provided
                above by Welcome Home Owner and to receive emails from
                welcomehomeowner.com
              </label>
            </CheckboxContainer>

            {error && <ErrorText>{error}</ErrorText>}

            <SignupButton type="submit">
              {loading ? 'Signing up..' : 'Sign Up'}
            </SignupButton>

            <LoginText>
              Already have an account?{' '}
              <span onClick={() => handleNavigate('/login')}>Login Here</span>
            </LoginText>
          </SignupContainerInputs>
        </form>
      </SignupContainer>
    </>
  );
};

export default Signup;
