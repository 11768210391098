import styled from 'styled-components';
import Banner from '../components/home-page/Banner';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { setSnackbar } from '../redux/features/snackbarSlice';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  min-height: 600px;

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  h1 {
    font-weight: 600;
    font-size: 2.8rem;
    text-align: center;
  }
`;

const SignupContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;

  input {
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgray;
  }
`;

const SignupButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
  color: white;
  padding: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  text-shadow: gray 1px 0 1px;
`;

const ErrorText = styled.p`
  font-size: 0.85rem;
  color: red;
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
`;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth?.user);
  const [formData, setFormData] = useState({
    password: '',
    password_confirmation: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');
  const email = queryParams.get('email');

  useEffect(() => {
    if (!token || !email) {
      return navigate('/');
    }
  }, [token, email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    // Check if passwords match
    if (formData.password !== formData.password_confirmation) {
      setError("Passwords don't match");
      return; // Exit early if validation fails
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password`,
        {
          token: token,
          email: email,
          password: formData.password,
          password_confirmation: formData.password_confirmation,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Your Password Has Been Reset!',
          type: 'success',
        }),
      );

      setFormData({
        password: '',
        password_confirmation: '',
      });

      setTimeout(() => {
        navigate('/');
      }, 500);
    } catch (error) {
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: error?.response?.data?.message,
          type: 'error',
        }),
      );
    }
  };
  return (
    <>
      <LoginContainer>
        <h1>Type your new password</h1>

        <form onSubmit={handleSubmit}>
          <SignupContainerInputs>
            <input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password*"
              required
            />

            <input
              name="password_confirmation"
              type="password"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder="Password Confirmation*"
              required
            />

            {error && <ErrorText>{error}</ErrorText>}

            <SignupButton>{loading ? 'Sending..' : 'Submit'}</SignupButton>
          </SignupContainerInputs>
        </form>
      </LoginContainer>
    </>
  );
};

export default ResetPassword;
