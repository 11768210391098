import styled from 'styled-components';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { setSnackbar } from '../redux/features/snackbarSlice';
import { useDispatch } from 'react-redux';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 2rem auto;
  width: 35%;
  min-height: 600px;

  @media (max-width: 1299px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 95%;
    gap: 1rem;
  }

  h1 {
    font-size: 3rem;

    @media (max-width: 1024px) {
      font-size: 2.4rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .legal {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
  }

  p {
    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;

  input {
    flex-basis: calc(50% - 1rem);
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgray;

    @media (max-width: 1024px) {
      flex-basis: 100%;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  outline: none;
  border: 1px solid lightgray;
`;

const SendButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #8ac926;
  color: white;
  padding: 12px;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
`;

const CheckboxGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  label {
    display: flex;

    input {
      margin-right: 0.5rem;
    }
  }
`;

const Contact = ({ captcha, trackClick, postTrackingData }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '',
    inquiryType: 'How can we help?',
    offersCategories: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showCategories, setShowCategories] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'inquiryType' && value === 'Offers I Like') {
      setShowCategories(true);
    } else {
      setShowCategories(false);
    }

    setFormData({
      ...formData,
      [name]: value.trimStart(),
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedCategories = [...formData.offersCategories];

    if (checked) {
      updatedCategories.push(value);
    } else {
      updatedCategories = updatedCategories.filter(
        (category) => category !== value,
      );
    }

    setFormData({
      ...formData,
      offersCategories: updatedCategories,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postTrackingData(
      `${(formData.firstName, formData.lastName)}`,
      formData.email,
      'https://welcomehomeowner.com',
      'Contact Us Form',
    );
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contacts`,
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          zip: formData.zipCode,
          inquiry_type: formData.inquiryType,
          categories: formData.offersCategories,
          'g-recaptcha-response': captcha,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Thank You For Contacting Us!',
          type: 'success',
        }),
      );

      setShowCategories(false);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        inquiryType: 'How can we help?',
        offersCategories: [],
      });
    } catch (error) {
      setLoading(false);

      setError(error?.response?.data?.error);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: error?.response?.data?.error,
          type: 'error',
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContactContainer>
        <h1>Contact Us</h1>
        <p>Let us know about you</p>

        <InputsContainer>
          <input
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name*"
            required
          />
          <input
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name*"
            required
          />
          <input
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address*"
            required
          />
          <input
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            placeholder="Zip Code*"
          />
        </InputsContainer>

        <Select
          name="inquiryType"
          value={formData.inquiryType}
          onChange={handleChange}
          required
        >
          <option value="">How can we help?</option>
          <option value="Offers I Like">Offers I Like</option>
          <option value="Advertising Inquiry">Advertising Inquiry</option>
          <option value="General Inquiry">General Inquiry</option>
          <option value="Opt Out">Opt Out</option>
        </Select>

        {showCategories && (
          <CheckboxGroup>
            <label>
              <input
                type="checkbox"
                value="Restaurants"
                onChange={handleCheckboxChange}
                checked={formData.offersCategories.includes('Restaurants')}
              />
              Restaurants
            </label>
            <label>
              <input
                type="checkbox"
                value="Automotive"
                onChange={handleCheckboxChange}
                checked={formData.offersCategories.includes('Automotive')}
              />
              Automotive
            </label>
            <label>
              <input
                type="checkbox"
                value="Home Services"
                onChange={handleCheckboxChange}
                checked={formData.offersCategories.includes('Home Services')}
              />
              Home Services
            </label>
            <label>
              <input
                type="checkbox"
                value="Shopping"
                onChange={handleCheckboxChange}
                checked={formData.offersCategories.includes('Shopping')}
              />
              Shopping
            </label>
            <label>
              <input
                type="checkbox"
                value="Events"
                onChange={handleCheckboxChange}
                checked={formData.offersCategories.includes('Events')}
              />
              Events
            </label>
          </CheckboxGroup>
        )}

        <SendButton>{loading ? 'SENDING...' : 'SELECT AND AGREE'}</SendButton>

        <p
          onClick={() => trackClick('Text', 'Consent Contact Us')}
          className="legal"
        >
          <p>
            By clicking "Select and Agree" I provide my ESIGN signature,
            confirming my agreement to the{' '}
            <a
              href="https://mrgstaging.com/policy/mrgtandc.html"
              target="_blank"
              rel="noreferer"
            >
              Terms and Conditions{' '}
            </a>
            and{' '}
            <a
              href="https://mrgstaging.com/policy/mrgprivacy.html"
              target="_blank"
              rel="noreferer"
            >
              Privacy Policy
            </a>{' '}
            I consent to allow Media Resource Group LLC the owner of the
            Welcomehomeowner.com website, their affiliates, or partners acting
            on their behalf, to contact me about their products and services at
            the phone number I provided. This contact may involve automated
            technology, SMS/MMS messages, AI-generated voice, or
            prerecorded/artificial voice messages. I acknowledge that my consent
            is not required to purchase any product or service. If I prefer to
            connect with Media Resource Group LLC regarding the
            Welcomehomeowner.com website without sharing my information, I can
            call <a href="tel:2396313553">(239)-631-3553</a>. Additionally, I
            understand that calls from Media Resource Group LLC may be recorded.
            This website also uses third-party session replay technology to
            monitor and record interactions for compliance purposes.
          </p>
        </p>
      </ContactContainer>
    </form>
  );
};

export default Contact;
