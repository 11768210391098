import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Header from './components/Header';
import Footer from './components/Footer';
import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OfferDetails from './pages/OfferDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setUuid } from './redux/features/uuidSlice';
import { setSnackbar } from './redux/features/snackbarSlice';
import axios from 'axios';
import MyAccount from './pages/MyAccount';
import MyFavorites from './pages/MyFavorites';
import NotFound from './pages/NotFound';

function App() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state?.snackbar);
  const [location, setLocation] = useState({ lat: null, long: null });
  const [captcha, setCaptcha] = useState();

  // Click tracking function
  const trackClick = (type, element) => {
    const clickData = {
      type,
      element,
      url: window.location.href,
    };

    axios
      .post('https://easy-panel-api.lim.bz/api/tracking/click', clickData, {
        headers: {
          'Content-Type': 'application/json',
          'SITE-TOKEN': 'efZS4yUDCU6yh4NJaozXydwWjnm1Ti4s',
        },
      })
      .then((response) => {
        console.log('Click tracking successful:', response.data);
      })
      .catch((error) => {
        console.error('Error tracking click:', error);
      });
  };

  //Form Submission function
  const headers = {
    'Content-Type': 'application/json',
    'SITE-TOKEN': 'efZS4yUDCU6yh4NJaozXydwWjnm1Ti4s',
  };

  const postTrackingData = async (name, email, url, form_type) => {
    try {
      const response = await axios.post(
        'https://easy-panel-api.lim.bz/api/tracking/form',
        {
          form_data: {
            name,
            email,
          },
          url,
          form_type,
        },
        { headers },
      );

      console.log('Tracking request success:', response.data);
    } catch (error) {
      console.error('Tracking request error:', error);
    }
  };

  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute('6LePGKwqAAAAAMGOL1PtMEQoIc6qhuyvjLw_JgOA', {
          action: 'submit',
        })
        .then((token) => {
          setCaptcha(token);
        });
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uuidFromUrl = searchParams.get('t');

    if (uuidFromUrl) {
      dispatch(setUuid(uuidFromUrl));
    }
  }, []);

  function getOrCreateUniqueID() {
    let uniqueID = localStorage.getItem('unique_user_id');

    if (!uniqueID) {
      uniqueID = crypto.randomUUID(); // Generates a unique ID
      localStorage.setItem('unique_user_id', uniqueID);
    }

    return uniqueID;
  }

  useEffect(() => {
    async function fetchUserIPAndTrack() {
      try {
        // Fetch user's IP address
        const ipResponse = await axios.get(
          'https://api64.ipify.org?format=json',
        );
        const userIP = ipResponse.data.ip;

        // Get unique user ID
        const uniqueID = getOrCreateUniqueID();

        // Prepare page view data
        const pageViewData = {
          url: window.location.href,
          user_agent: navigator.userAgent,
          referrer: document.referrer,
          unique_id: uniqueID,
          ip_address: userIP,
        };

        // Send tracking data
        const response = await axios.post(
          'https://easy-panel-api.lim.bz/api/tracking/pageview',
          pageViewData,
          {
            headers: {
              'Content-Type': 'application/json',
              'SITE-TOKEN': 'efZS4yUDCU6yh4NJaozXydwWjnm1Ti4s',
            },
          },
        );

        console.log('Pageview tracking successful:', response.data);
      } catch (error) {
        console.error('Error tracking pageview:', error.message);
      }
    }

    fetchUserIPAndTrack();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header trackClick={trackClick} />
        <Routes>
          <Route
            path="/"
            element={<Home location={location} trackClick={trackClick} />}
          />
          <Route
            path="/about"
            element={
              <About
                captcha={captcha}
                trackClick={trackClick}
                postTrackingData={postTrackingData}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                captcha={captcha}
                trackClick={trackClick}
                postTrackingData={postTrackingData}
              />
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/offer/:id" element={<OfferDetails />} />
          {/* <Route path="/my-account" element={<MyAccount />} />
          <Route path="/my-favorites" element={<MyFavorites />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer
          captcha={captcha}
          trackClick={trackClick}
          postTrackingData={postTrackingData}
        />
      </BrowserRouter>

      {snackbar.isOpen && (
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={2000}
          onClose={() =>
            dispatch(setSnackbar({ isOpen: false, message: '', type: '' }))
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ width: '340px' }}
        >
          <Alert
            onClose={() =>
              dispatch(setSnackbar({ isOpen: false, message: '', type: '' }))
            }
            severity={snackbar.type}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default App;
