import styled from 'styled-components';
import Banner from '../components/home-page/Banner';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { setSnackbar } from '../redux/features/snackbarSlice';

const AboutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 80px 160px;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;

  @media (max-width: 1449px) {
    margin: 60px 80px;
  }

  @media (max-width: 768px) {
    margin: 40px 20px;
  }
`;

const AboutContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 60%;

  @media (max-width: 1249px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    min-width: 100%;
  }

  h1 {
    font-size: 3rem;
    font-weight: 800;
    color: #3d3d3d;

    @media (max-width: 1024px) {
      font-size: 2.4rem;
    }

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;

    @media (max-width: 1024px) {
      font-size: 1.8rem;
    }

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    font-family: 'Open sans', sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #3d3d3d;

    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  h3 {
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }
  }

  ul {
    margin: 0;
    width: 650px;

    @media (max-width: 768px) {
      width: 100%;
    }

    li {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 1rem auto 1rem 1.2rem;

      @media (max-width: 1024px) {
        font-size: 1rem;
      }

      @media (max-width: 768px) {
        font-size: 0.8rem;
        margin: 1rem auto 1rem 0.75rem;
      }
    }
  }
`;

const AboutContainerRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  img {
    width: 700px;
    height: auto;
    border-radius: 12px;
    -webkit-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const BecomePartnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 80px 160px;

  @media (max-width: 1449px) {
    margin: 60px 80px;
  }

  @media (max-width: 1249px) {
    margin: 60px;
  }

  @media (max-width: 768px) {
    margin: 40px 20px;
    gap: 2rem;
  }
`;

const BecomePartnerContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
  flex: 1;

  @media (max-width: 1189px) {
    min-width: 100%;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-size: 3rem;

    @media (max-width: 1249px) {
      font-size: 2.6rem;
    }

    @media (max-width: 1024px) {
      font-size: 2rem;
    }
  }

  p {
    font-family: 'Open sans', sans-serif;
    font-size: 0.88rem;
    line-height: 1.5rem;
    color: #3d3d3d;

    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  img {
    width: 600px;
    height: auto;
    border-radius: 12px;
    -webkit-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
    box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);

    @media (max-width: 1549px) {
      width: 500px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const BecomePartnerContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

  @media (max-width: 1189px) {
    min-width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }

  p {
    font-size: 0.7rem;
    color: lightgray;
    line-height: 1rem;
  }
`;

const AdvertiseWithUsContainer = styled.div`
  border-radius: 18px;
  background-color: #009fc2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1.5rem;
  color: white;
  -webkit-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
  -moz-box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
  box-shadow: 3px 2px 11px -6px rgba(66, 68, 90, 1);
  min-width: 500px;
  max-width: 500px;

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.75);
    text-align: justify;
  }

  a {
    color: white;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;

  input {
    flex-basis: calc(50% - 1rem);
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
      flex-basis: 75%;
    }

    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }
`;

const Button = styled.button`
  width: 100%;
  cursor: pointer;
  border: 2px solid #d4f4bb;
  outline: none;
  border-radius: 8px;
  background: linear-gradient(to top, #7ac143, #94e656);
  color: white;
  padding: 12px;
  font-weight: 800;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-top: 1rem;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
`;

const About = ({ captcha, trackClick, postTrackingData }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.user);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '',
    state: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trimStart(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postTrackingData(
      `${(formData.firstName, formData.lastName)}`,
      formData.email,
      'https://welcomehomeowner.com',
      'Advertise Form',
    );
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/advertise`,
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          zip: formData.zipCode,
          state: formData.state,
          phone: formData.phone,
          'g-recaptcha-response': captcha,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Thank You For Contacting Us!',
          type: 'success',
        }),
      );

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        state: '',
        phone: '',
      });
    } catch (error) {
      setLoading(false);

      setError(error?.response?.data?.error);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: error?.response?.data?.error,
          type: 'error',
        }),
      );
    }
  };

  return (
    <>
      <BecomePartnerContainer>
        <BecomePartnerContainerLeft>
          <h2>Become A Partner!</h2>

          <img alt="Website Logo" src="/hand-shake.jpg" />
        </BecomePartnerContainerLeft>

        <BecomePartnerContainerRight>
          <form onSubmit={handleSubmit}>
            <AdvertiseWithUsContainer>
              <h2>Advertise With Us!</h2>

              <InputsContainer>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name*"
                  required
                />
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name*"
                  required
                />
                <input
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone*"
                  required
                />
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email*"
                  required
                />
                <input
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State*"
                  required
                />
                <input
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  placeholder="Zip*"
                  required
                />
              </InputsContainer>

              <Button>{loading ? 'Sending..' : 'Contact Us'}</Button>

              <p
                onClick={() => trackClick('Text', 'Consent Advertise With Us')}
              >
                By clicking "Contact Us" I provide my ESIGN signature,
                confirming my agreement to the{' '}
                <a
                  href="https://mrgstaging.com/policy/mrgtandc.html"
                  target="_blank"
                  rel="noreferer"
                >
                  Terms and Conditions{' '}
                </a>
                and{' '}
                <a
                  href="https://mrgstaging.com/policy/mrgprivacy.html"
                  target="_blank"
                  rel="noreferer"
                >
                  Privacy Policy
                </a>{' '}
                I consent to allow Media Resource Group LLC the owner of the
                Welcomehomeowner.com website, their affiliates, or partners
                acting on their behalf, to contact me about their products and
                services at the phone number I provided. This contact may
                involve automated technology, SMS/MMS messages, AI-generated
                voice, or prerecorded/artificial voice messages. I acknowledge
                that my consent is not required to purchase any product or
                service. If I prefer to connect with Media Resource Group LLC
                regarding the Welcomehomeowner.com website without sharing my
                information, I can call{' '}
                <a href="tel:2396313553">(239)-631-3553</a>. Additionally, I
                understand that calls from Media Resource Group LLC may be
                recorded. This website also uses third-party session replay
                technology to monitor and record interactions for compliance
                purposes.
              </p>
            </AdvertiseWithUsContainer>
          </form>
        </BecomePartnerContainerRight>
      </BecomePartnerContainer>

      <AboutContainer>
        <AboutContainerLeft>
          <h1>About Welcome HOME</h1>

          <h2>Reach New Homeowners through a weekly new Homeowner mailer:</h2>

          <p>
            The New Homeowner program is a cooperative mailing where advertisers
            can reach new homeowners via direct mail kit received at the
            homeowner's new mailbox within 3 to 4 weeks of their move.
            Advertiser's offers are included in this kit to help new homeowners
            conveniently acquire necessary products and services.
          </p>

          <h2>Benefits</h2>

          <ul>
            <li>Total circulation is 3,600,000 annually</li>
            <li>Targets only new homeowners, no renters</li>
            <li>Program mails bi-weekly</li>
            <li>
              Enjoy the benefits of featuring highly personalized offers at a
              fraction of the cost of solo mail advertising
            </li>
            <li>
              New movers spend more in the first 6 months of their move than
              they will in the next 3 years
            </li>
            <li>
              72% of new homeowners will spend up to 60,000 in the first year
            </li>
            <li>
              New residents establish an average of 71 new business
              relationships in their first few months
            </li>
          </ul>

          <h2>Feature your offer as an Affiliate Brand on our website.</h2>
          <p>
            Reach homeowners who have just moved into their new home and are
            self-selecting offers and services they need.
          </p>

          <h2>Top offers requested by New homeowners</h2>

          <ul>
            <li>Cable/Satellite TV/Home Entertainment </li>
            <li>Home Security</li>
            <li>Home Remodeling Services</li>
            <li>Medical & Dental Services</li>
            <li>Home Décor, Furniture & Appliances</li>
            <li>Veterinary Services</li>
            <li>Insurance</li>
            <li>Flooring</li>
            <li>Pest Control</li>
            <li>Lawn & Gardening Equipment</li>
          </ul>
        </AboutContainerLeft>

        {/* <AboutContainerRight>
          <img alt="Website Logo" src="/moving-family.jpg" />
        </AboutContainerRight> */}
      </AboutContainer>

      <Banner trackClick={trackClick} />
    </>
  );
};

export default About;
