import styled from 'styled-components';
import Banner from '../components/home-page/Banner';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { loginUser } from '../redux/features/authSlice';
import { setSnackbar } from '../redux/features/snackbarSlice';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  h1 {
    font-weight: 600;
    font-size: 2.8rem;
  }

  form {
    width: 50%;

    @media (max-width: 768px) {
      width: 95%;
    }
  }
`;

const SignupContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;

  input {
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgray;
  }
`;

const SignupButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  border: 2px solid #d4f4bb;
  background: linear-gradient(to top, #7ac143, #94e656);
  color: white;
  padding: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  text-shadow: gray 1px 0 1px;
`;

const RegisterText = styled.p`
  flex-basis: 100%;
  margin-top: 2rem;
  font-size: 0.9rem;
  text-align: center;
  color: black;

  span {
    color: #008c9a;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`;

const ForgotPasswordText = styled.p`
  flex-basis: 100%;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
  color: black;

  span {
    color: #008c9a;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`;

const ErrorText = styled.p`
  font-size: 0.85rem;
  color: red;
  text-align: center;
  font-weight: 600;
`;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.user);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (auth?.token) {
      navigate('/');
    }
  }, [auth]);

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value.trimStart(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const trimmedEmail = formData.email.trim();
    const trimmedPassword = formData.password.trim();

    if (!trimmedEmail || !trimmedPassword) {
      setError('Both email and password are required.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {
          email: trimmedEmail,
          password: trimmedPassword,
        },
      );
      setLoading(false);

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'Logged In Successfully',
          type: 'success',
        }),
      );

      const userWithToken = {
        user: response?.data?.user,
        token: response?.data?.token,
      };

      dispatch(loginUser(userWithToken));

      setFormData({
        email: '',
        password: '',
      });

      setTimeout(() => {
        navigate('/');
      }, 500);
    } catch (error) {
      setLoading(false);

      setError(
        error?.response?.data?.error || 'There was a problem logging in.',
      );

      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'There was a problem logging in.',
          type: 'error',
        }),
      );
    }
  };
  return (
    <>
      <LoginContainer>
        <h1>Login</h1>

        <form onSubmit={handleSubmit}>
          <SignupContainerInputs>
            <input
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address*"
              required
            />
            <input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password*"
              required
            />

            {error && <ErrorText>{error}</ErrorText>}

            <SignupButton>{loading ? 'Logging in..' : 'Log In'}</SignupButton>
          </SignupContainerInputs>
        </form>

        <RegisterText>
          Don't have a Welcome HOME account?{' '}
          <span onClick={() => handleNavigate('/signup')}>Sign up Here</span>
        </RegisterText>
        <ForgotPasswordText>
          Forgot Password?{' '}
          <span onClick={() => handleNavigate('/forgot-password')}>
            Click to reset
          </span>
        </ForgotPasswordText>
      </LoginContainer>

      <Banner />
    </>
  );
};

export default Login;
