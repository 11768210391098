import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offers: [],
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffersRedux: (state, action) => {
      state.offers = action.payload;
    },
  },
});

export const { setOffersRedux } = offersSlice.actions;

export default offersSlice.reducer;
